var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uk-flex uk-flex-center uk-flex-middle bg-white",attrs:{"uk-height-viewport":"","data-src":_vm.backgrounds[Math.floor(Math.random() * _vm.backgrounds.length)],"uk-img":""}},[_c('div',[_c('div',{staticClass:"border border-gray-200 shadow-2xl rounded-2xl uk-width-medium uk-padding"},[_c('div',{staticClass:"uk-text-center"},[_c('img',{attrs:{"src":_vm.api + '/storefronts/' + _vm.storefrontId + '/logo',"alt":""}})]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('ValidationProvider',{attrs:{"name":"Nombres","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.first_name),expression:"model.first_name"}],staticClass:"uk-margin-top uk-input uk-text-center uk-border-rounded",attrs:{"type":"text","placeholder":"Nombres","name":"Nombres","autocomplete":"off"},domProps:{"value":(_vm.model.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "first_name", $event.target.value)}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Apellidos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.last_name),expression:"model.last_name"}],staticClass:"uk-margin-top uk-input uk-text-center uk-border-rounded",attrs:{"id":"last_name","type":"text","placeholder":"Apellidos","name":"Apellidos","autocomplete":"off"},domProps:{"value":(_vm.model.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "last_name", $event.target.value)}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Celular","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.mobile_phone),expression:"model.mobile_phone"}],staticClass:"uk-margin-top uk-input uk-text-center uk-border-rounded",attrs:{"id":"mobile_phone","type":"text","placeholder":"Celular","name":"Celular","autocomplete":"off"},domProps:{"value":(_vm.model.mobile_phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "mobile_phone", $event.target.value)}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Teléfono"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.phone),expression:"model.phone"}],staticClass:"uk-margin-top uk-input uk-text-center uk-border-rounded",attrs:{"id":"phone","type":"text","placeholder":"Teléfono","name":"Teléfono","autocomplete":"off"},domProps:{"value":(_vm.model.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "phone", $event.target.value)}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Correo","rules":"required|email|confirmed:email_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.email),expression:"model.email"}],staticClass:"uk-margin-top uk-input uk-text-center uk-border-rounded",attrs:{"id":"email","type":"text","placeholder":"Correo","name":"Correo","autocomplete":"off"},domProps:{"value":(_vm.model.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "email", $event.target.value)}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"email_confirmation","name":"Confirmación de correo","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.email_confirmation),expression:"model.email_confirmation"}],staticClass:"uk-margin-top uk-input uk-text-center uk-border-rounded",attrs:{"id":"email_confirmation","type":"text","placeholder":"Confirmación de correo","name":"Confirmación de correo","autocomplete":"off"},domProps:{"value":(_vm.model.email_confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "email_confirmation", $event.target.value)}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Contraseña","rules":"required|confirmed:password_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('PasswordInput',{attrs:{"name":"Contraseña"},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Confirmación de contraseña","rules":"required","vid":"password_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('PasswordInput',{attrs:{"name":"Confirmación de Contraseña"},model:{value:(_vm.model.password_confirmation),callback:function ($$v) {_vm.$set(_vm.model, "password_confirmation", $$v)},expression:"model.password_confirmation"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"uk-text-center uk-margin-top"},[_c('button',{staticClass:"uk-button uk-button-primary uk-border-rounded",attrs:{"disabled":invalid},on:{"click":function($event){return _vm.register(_vm.model)}}},[_vm._v(" Crear cuenta ")])])]}}])})],1),_c('div',{staticClass:"uk-text-center uk-margin-top"},[_c('router-link',{staticClass:"bg-gray-300 px-4 py-2 rounded-full text-gray-900",attrs:{"to":"/login"}},[_vm._v(" Ya tengo una cuenta ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }